@import url('https://fonts.googleapis.com/css?family=Expletus+Sans|Special+Elite&display=swap');

.contact-container {
    display: inline-block;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.phoneContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

h2 {
    font-family: 'Special Elite', cursive;
}

.phone {
    max-width: 40px;
}

h4 {
    list-style-type: none;
    font-family: 'Expletus Sans', cursive;
    font-weight: bolder;
}

.gmailIcon {
    max-width: 75px;
}

.link {
    max-width: 80px;
}

.git {
    max-width: 75px;
}

.phoneNum {
    font-family: 'Expletus Sans', cursive;
    font-weight: bolder;
    color: black;
}