.projects-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 15px;
}

.projects {
    width: 600px;
    height: 300px;
    border: 1px solid black;
    margin: 10px;
    padding: 10px;
    background-size: cover;
    background-position: center;
}

.soon {
    font-family: 'Expletus Sans', cursive;
    font-weight: bolder;
    font-size: 25px;
    color: black;
}

@media screen and (max-width: 425px) {
    .projects-container {
        width: 90%;
    }
    .projects {
        width: 75%;
        height: 150px;
    }
}