@import url('https://fonts.googleapis.com/css?family=Expletus+Sans|Special+Elite&display=swap');

.App {
  text-align: center;
}

.nav-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background-image: url("./images/blueline2.jpg");
  background-size: contain;
  background-position: center;
}

.home {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

h3 {
  color: ghostwhite;
  text-emphasis-style: none;
  font-family: 'Special Elite', cursive;
  font-size: 30px;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 425px) {
  .nav-bar {
    width: 100%;
  }

  .home{
    height: 750px;
    background-size: cover;
    background-position: center;
  }

  h3 {
    font-size: 15px;
  }
}

@media screen and (max-width: 770px) {
  .home{
    height: 1030px;
    background-size: cover;
    background-position: center;
  }
}