.resume {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.AlexG {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 35px;
}

.underline {
    width: 100%;
    height: 0px;
    border: 2px solid rgb(48, 149, 169);
}

.underText {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
}

.resumeBody {
    width: 70%;
}

.points {
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
}

.holder {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
}

.job {
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
}

.date {
    font-family: Arial, Helvetica, sans-serif;
    text-align: right;
}

.resumeText {
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    font-size: 13px;
    margin: auto;
}

.skills {
    display: inline-table;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    font-size: 13px;
    padding: 10px;
}

.educate {
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    font-size: 13px;
    margin-bottom: 15px;
}