@import url('https://fonts.googleapis.com/css?family=Expletus+Sans|Special+Elite&display=swap');

.about-container {
    text-align: center;
}
.alex {
    margin: 20px;
    width: 300px;
    height: 300px;
    border: 1px solid black;
    border-radius: 130px;
}

h1 {
    font-family: 'Special Elite', cursive;
}


.aboutMe {
    font-family: 'Expletus Sans', cursive;
    text-align: center;
    width: 70%;
    margin: 20px auto;
    padding-bottom: 10px;
}

@media screen and (max-width: 425px) {
    .alex {
        width: 200px;
        height: 200px;
        border-radius: 80px;
    }

}